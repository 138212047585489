/* Reset Styles */
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  overflow-x: hidden;
  min-height: 100vh;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Header */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px;
  background-color: #333;
  color: white;
  position: fixed; /* Fixed header */
  top: 0; /* Stick to the top */
  left: 0;
  z-index: 10;
  flex-wrap: wrap;
  height: 60px; /* Define fixed height for header */
}

.header__logo h1 {
  font-size: 24px;
  color: aquamarine;
}

.header__nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.header__nav li {
  margin: 0 10px;
}

.header__nav a {
  color: white;
  text-decoration: none;
  font-size: 16px;
}

.header__nav a:hover {
  color: aquamarine;
}

/* Footer */
.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 15px 0;
  position: fixed; /* Fixed footer */
  bottom: 0; /* Stick to the bottom */
  left: 0;
  width: 100%;
  z-index: 9;
  font-size: 12px;
  height: 50px; /* Define fixed height for footer */
}

/* Content Pages */
.redvoznje-page {
  padding-top: 60px; /* Push content below fixed header */
  padding-bottom: 50px; /* Push content above fixed footer */
  overflow-y: auto; /* Allow scrolling if needed */
  min-height: calc(
    100vh - 110px
  ); /* Viewport height minus header/footer height */
  box-sizing: border-box;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .header {
    padding: 10px;
    height: 55px; /* Adjust height for smaller screens */
  }

  .header__logo h1 {
    font-size: 22px;
  }

  .header__nav ul {
    justify-content: center;
    padding-top: 10px;
  }

  .header__nav li {
    margin: 0 5px;
  }

  .header__nav a {
    font-size: 14px;
  }

  .footer {
    padding: 10px 0;
    font-size: 12px;
    height: 45px; /* Adjust height for smaller screens */
  }

  .redvoznje-page {
    padding-top: 55px;
    padding-bottom: 45px;
    min-height: calc(100vh - 100px);
  }
}

@media (max-width: 480px) {
  .header {
    padding: 10px;
    height: 50px; /* Adjust height for smallest screens */
  }

  .header__logo h1 {
    font-size: 20px;
  }

  .header__nav ul {
    justify-content: center;
    padding-top: 5px;
  }

  .header__nav li {
    margin: 5px 0;
  }

  .header__nav a {
    font-size: 13px;
  }

  .footer {
    padding: 8px 0;
    font-size: 11px;
    height: 40px; /* Adjust height for smallest screens */
  }

  .redvoznje-page {
    padding-top: 50px;
    padding-bottom: 40px;
    min-height: calc(100vh - 90px);
  }
}
