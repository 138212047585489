/* SwipeableBusSchedule.css */
.swipe-container {
  width: 100vw; /* Full viewport width */

  display: flex;
  flex-direction: column; /* Stack elements vertically */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  text-align: center;
  background-color: #f5f5f5; /* Light background color */
  overflow: hidden; /* Prevent overflow issues */
}

.swipe-content {
  width: 90%; /* Slightly smaller than full width for spacing */
  max-width: 400px; /* Limit max width for better readability */
  padding: 20px;
  background-color: #ffffff; /* White background */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: transform 0.3s ease; /* Smooth transitions */
  height: fit-content;
}

.swipe-content h2 {
  font-size: 1.8em; /* Large heading for the line name */
  margin-bottom: 10px;
  color: #333333; /* Dark text color */
}

.swipe-content h3 {
  font-size: 1.5em; /* Medium heading for the station name */
  margin-bottom: 15px;
  color: #666666; /* Slightly lighter text color */
}

.swipe-content ul {
  list-style-type: none; /* Remove bullet points */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
}

.swipe-content li {
  font-size: 1.2em; /* Make times easy to read */
  padding: 10px 0; /* Add vertical spacing */
  color: #444444; /* Neutral text color */
  border-bottom: 1px solid #ddd; /* Divider between times */
}

.swipe-content li:last-child {
  border-bottom: none; /* Remove last divider */
}
/* Media Query for Small Screens (Mobile) */
@media (max-height: 700px) {
  .swipe-content {
    padding: 10px; /* Reduce padding to save space */
  }

  .swipe-content h2 {
    font-size: 1.2em; /* Smaller font size */
    margin-bottom: 5px;
  }

  .swipe-content h3 {
    font-size: 1em; /* Smaller font size */
    margin-bottom: 8px;
  }

  .swipe-content li {
    font-size: 0.9em; /* Smaller font size for list items */
    padding: 4px 0; /* Adjust spacing for compact layout */
  }
}
