.weather-container {
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  max-width: 300px;
  margin: 20px auto;
  text-align: center;
}

.weather-container h2 {
  margin-bottom: 15px;
}

.weather-container p {
  margin: 5px 0;
}

.weather-loading,
.weather-error {
  text-align: center;
  font-size: 16px;
  color: #555;
}
