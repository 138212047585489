.search-input {
  padding: 5px;
  margin-bottom: 5px;
  margin-top: 10px;
  width: 50%;
  font-size: 20px;
  border: 2px solid #333;
  border-radius: 5px;
}
.pretraga {
  text-align: left;
  margin-left: 10px;
}

.store-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.store-table th,
.store-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.store-table th {
  background-color: #f4f4f4;
}

.store-row {
  cursor: pointer;
}

.store-row:hover {
  background-color: #f9f9f9;
}

.store-row.open {
  color: #4caf50; /* Green color for open stores */
}

.store-row.closed {
  color: #f44336; /* Red color for closed stores */
}

.store-details-row {
  background-color: #f9f9f9;
}

.store-details {
  padding: 15px;
  padding-bottom: 15px;
}

.store-details h3 {
  margin-top: 0;
}

.store-details ul {
  list-style-type: none;
  padding: 0;
}

.store-details li {
  margin-bottom: 5px;
}
