/* Styles scoped to the Redvoznje component */

/* Main container styling */
.redvoznje-container {
  margin: 20px;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Heading styling */
.redvoznje-heading {
  text-align: center;
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 20px;
}

/* Unordered list styling */
.redvoznje-list {
  list-style: none;
  padding: 0;
}

/* List items styling */
.redvoznje-list li {
  margin-bottom: 15px;
}

/* Link styling */
.redvoznje-link {
  text-decoration: none;
  color: #1e90ff; /* DodgerBlue color */
  font-size: 1.2rem;
  padding: 10px 15px;
  display: block;
  border: 2px solid transparent;
  border-radius: 4px;
  transition: all 0.3s ease;
}

/* Hover effects for links */
.redvoznje-link:hover {
  background-color: #1e90ff;
  color: white;
  border-color: #1e90ff;
}

/* Active or focused link styling */
.redvoznje-link:focus,
.redvoznje-link:active {
  outline: none;
  border-color: #ff6347; /* Tomato color */
}

/* Responsive Design for smaller screens */
@media (max-width: 600px) {
  .redvoznje-link {
    font-size: 1rem;
    padding: 8px 12px;
  }

  .redvoznje-heading {
    font-size: 1.5rem;
  }
}
