.bus-schedule-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.bus-table {
  border: 1px solid #ccc;
  margin: 20px;
  padding: 10px;
  width: 200px;
}

.bus-table h2 {
  text-align: center;
  margin-bottom: 10px;
}

.bus-table table {
  width: 100%;
  border-collapse: collapse;
}

.bus-table th,
.bus-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

/* Alternating row colors for this specific table */
.bus-table tr:nth-child(even) {
  background-color: #fff; /* Light gray for even rows */
}

.bus-table tr:nth-child(odd) {
  background-color: #f9f9f9; /* White for odd rows */
}

/* Highlight the next available bus times */
.highlight {
  background-color: #3eff3b;
  font-weight: bold;
  color: #f70c0c; /* Darker text for contrast */
}
