/* Main container styling for the WorkingTimes component */
.tabelaradnogvremena {
  margin: 20px;
  padding: 20px;
  max-width: 800px;
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
}

/* Heading styling */
.tabelaradnogvremena h2 {
  text-align: center;
  font-size: 1.8rem;
  color: #444;
  margin-bottom: 20px;
}

/* Unordered list styling */
.lista {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* List item styling */
.prodavnice {
  margin-bottom: 15px;
}

/* Link styling inside the list items */
.prodavnice a {
  text-decoration: none;
  color: #007bff; /* Link color */
  font-size: 1.2rem;
  padding: 10px 15px;
  display: block;
  border: 2px solid transparent;
  border-radius: 4px;
  transition: all 0.3s ease;
}

/* Hover effect for the links */
.prodavnice a:hover {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

/* Focus and active state for accessibility */
.prodavnice a:focus,
.prodavnice a:active {
  outline: none;
  border-color: #ff4500; /* Orange on focus */
}

/* Responsive design for smaller screens */
@media (max-width: 600px) {
  .tabelaradnogvremena {
    padding: 15px;
  }

  .tabelaradnogvremena h2 {
    font-size: 1.5rem;
  }

  .prodavnice a {
    font-size: 1rem;
    padding: 8px 12px;
  }
}
